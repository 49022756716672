// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-template-js": () => import("/opt/build/repo/src/templates/index-template.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-keyboard-shortcut-template-js": () => import("/opt/build/repo/src/templates/keyboard-shortcut-template.js" /* webpackChunkName: "component---src-templates-keyboard-shortcut-template-js" */),
  "component---src-templates-random-template-js": () => import("/opt/build/repo/src/templates/random-template.js" /* webpackChunkName: "component---src-templates-random-template-js" */),
  "component---src-templates-random-news-template-js": () => import("/opt/build/repo/src/templates/random-news-template.js" /* webpackChunkName: "component---src-templates-random-news-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

